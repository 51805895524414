import React from 'react';
import {Radio, RadioGroup} from '@blueprintjs/core';

const RadioInput = (props) => {
    const {formikFieldProps, inputProps, value} = props;

    return (
        <RadioGroup
            {...formikFieldProps}
            {...inputProps}
            selectedValue={value}
            label={null}
        />
    );
};

export default RadioInput;