// applicationUser
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';

// round
export const FETCH_ROUND = 'FETCH_ROUND';
export const FETCH_ROUND_SUCCESS = 'FETCH_ROUND_SUCCESS';
export const FETCH_ROUND_FAILED = 'FETCH_ROUND_FAILED';
export const SUBMIT_GAME_ROUND_ANSWER = 'SUBMIT_GAME_ROUND_ANSWER';
export const SUBMIT_GAME_ROUND_ANSWER_SUCCESS = 'SUBMIT_GAME_ROUND_ANSWER_SUCCESS';
export const SUBMIT_GAME_ROUND_ANSWER_FAILED = 'SUBMIT_GAME_ROUND_ANSWER_FAILED';

// application
export const UPDATE_GAME_STATE = 'UPDATE_GAME_STATE';
export const GO_TO_NEXT_ROUND = 'GO_TO_NEXT_ROUND';
export const GO_TO_PREVIOUS_ROUND = 'GO_TO_PREVIOUS_ROUND';
export const GO_TO_NEXT_ROUND_AFTER_GAME_ROUND_RESULT_DISPLAYED = 'GO_TO_NEXT_ROUND_AFTER_GAME_ROUND_RESULT_DISPLAYED';
export const RESET_GAME_STATE_WHEN_MAIN_GAME_ROUND_BEGINS = 'RESET_GAME_STATE_WHEN_MAIN_GAME_ROUND_BEGINS';
export const SELECT_APPLICATION_LANGUAGE = 'SELECT_APPLICATION_LANGUAGE';
export const CHANGE_HIDE_BUTTON_STATE = 'CHANGE_HIDE_BUTTON_STATE';

// webform
export const FETCH_WEBFORM_ELEMENTS = 'FETCH_WEBFORM_ELEMENTS';
export const FETCH_WEBFORM_ELEMENTS_SUCCESS = 'FETCH_WEBFORM_ELEMENTS_SUCCESS';
export const FETCH_WEBFORM_ELEMENTS_FAILED = 'FETCH_WEBFORM_ELEMENTS_FAILED';
export const FETCH_WEBFORM_FIELDS = 'FETCH_WEBFORM_FIELDS';
export const FETCH_WEBFORM_FIELDS_SUCCESS = 'FETCH_WEBFORM_FIELDS_SUCCESS';
export const FETCH_WEBFORM_FIELDS_FAILED = 'FETCH_WEBFORM_FIELDS_FAILED';
export const SUBMIT_WEBFORM = 'SUBMIT_WEBFORM';
export const SUBMIT_WEBFORM_SUCCESS = 'SUBMIT_WEBFORM_SUCCESS';
export const SUBMIT_WEBFORM_SUCCESS_FAILED = 'SUBMIT_WEBFORM_SUCCESS_FAILED';

// languages
export const FETCH_LANGUAGES = 'FETCH_LANGUAGES';
export const FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS';
export const FETCH_LANGUAGES_FAILED = 'FETCH_LANGUAGES_FAILED';

// content
export const FETCH_CONTENT_BY_ID = 'FETCH_CONTENT_BY_ID';
export const FETCH_CONTENT_BY_ID_SUCCESS = 'FETCH_CONTENT_BY_ID_SUCCESS';
export const FETCH_CONTENT_BY_ID_FAILED = 'FETCH_CONTENT_BY_ID_FAILED';

// message
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAILED = 'FETCH_MESSAGES_FAILED';