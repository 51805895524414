import React from 'react';
import {useSelector} from 'react-redux';
import messageProjections from '../../projections/message';

const Footer = (props) => {
    const {} = props;
    const footerMessage = useSelector(messageProjections.messageValueFromCode('app-footer'));

    return (
        <div className={'footer'}>
            {footerMessage}
        </div>
    );
};

export default Footer;