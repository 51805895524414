import {createSelector} from '@reduxjs/toolkit';

const messageProjections = {};

messageProjections.messageValueFromCode = code => createSelector(
    state => state.message.message.byId[code],
    message => {
        return message ? message.value : '';
    },
);

export default messageProjections;