import * as ActionTypes from '../actions/types';
import xs from 'xstream';
import {BASE_URL} from '../utils/constants/config';
import * as actions from '../actions';import delay from 'xstream/extra/delay';
import sampleCombine from 'xstream/extra/sampleCombine';
import generalFunctions from '../utils/functions/general';

const REQUEST_MESSAGES = 'requestMessages';

export function requestMessages(sources) {
    const state$ = sources.STATE;
    const csrfToken$ = state$.map(state => state.applicationUser.csrfToken);
    const sid$ = state$.map(state => state.language.language.byId['sid']);

    const request$ = sources.ACTION
        .filter((action) =>  action.type === ActionTypes.FETCH_MESSAGES)
        .compose(delay())
        .compose(sampleCombine(csrfToken$))
        .compose(sampleCombine(sid$))
        .map(([combined, sid]) => {
            const [action, csrfToken] = combined;
            return {
                url: BASE_URL + 'translations',
                category: REQUEST_MESSAGES,
                method: 'POST',
                send: action.payload.sendBody,
                withCredentials: false,
                headers: generalFunctions.postHeader(csrfToken, sid),
            };
        });

    let httpResponse$ = sources.HTTP.select(REQUEST_MESSAGES)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response => (
            response.status === 200
                ? actions.fetchMessagesSuccess(response)
                : actions.fetchMessagesFailed(response)
        ));

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}
