import * as ActionTypes from './types';
import {createAction} from '@reduxjs/toolkit';

export const fetchWebformElements = createAction(ActionTypes.FETCH_WEBFORM_ELEMENTS);
export const fetchWebformElementsSuccess = createAction(ActionTypes.FETCH_WEBFORM_ELEMENTS_SUCCESS);
export const fetchWebformElementsFailed = createAction(ActionTypes.FETCH_WEBFORM_ELEMENTS_FAILED);

export const fetchWebformFields = createAction(ActionTypes.FETCH_WEBFORM_FIELDS);
export const fetchWebformFieldsSuccess = createAction(ActionTypes.FETCH_WEBFORM_FIELDS_SUCCESS);
export const fetchWebformFieldsFailed = createAction(ActionTypes.FETCH_WEBFORM_FIELDS_FAILED);

export const submitWebform = createAction(ActionTypes.SUBMIT_WEBFORM);
export const submitWebformSuccess = createAction(ActionTypes.SUBMIT_WEBFORM_SUCCESS);
export const submitWebformFailed = createAction(ActionTypes.SUBMIT_WEBFORM_SUCCESS_FAILED);