import * as ActionTypes from '../actions/types';
import xs from 'xstream';
import {BASE_URL} from '../utils/constants/config';
import * as actions from '../actions';import delay from 'xstream/extra/delay';

const REQUEST_LANGUAGES = 'requestLanguages';

export function requestLanguages(sources) {
    const state$ = sources.STATE;
    const csrfToken$ = state$.map(state => state.applicationUser.csrfToken);

    const request$ = sources.ACTION
        .filter((action) =>  action.type === ActionTypes.FETCH_LANGUAGES)
        .compose(delay())
        .map(action => ({
            url: BASE_URL + 'scenarios/language',
            category: REQUEST_LANGUAGES,
            method: 'POST',
            send: action.payload && action.payload.scenarioVersionId
                ? {scenarioVersionId: action.payload.scenarioVersionId}
                : {},
            withCredentials: false,
        }));

    let httpResponse$ = sources.HTTP.select(REQUEST_LANGUAGES)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response => (
            response.status === 200
                ? actions.fetchLanguagesSuccess(response)
                : actions.fetchLanguagesFailed(response)
        ));

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}
