import {createSelector} from '@reduxjs/toolkit';
import generalFunctions from '../utils/functions/general';
import messageProjections from './message';

const roundProjections = {};

roundProjections.gameRound = createSelector(
    state => state.round.round.data,
    state => state.message.message.byId['game-round-trader'],
    (gameRoundData, traderMessage) => {
        if(!gameRoundData || (typeof gameRoundData !== 'object')){
            return  null;
        }

        return {
            isTrial: gameRoundData.isTrial === '1',
            ships: gameRoundData.nameset
                .map((name, index) => ({
                    name: name,
                    index: index,
                })),
            traders: gameRoundData.tippstructures
                .map((structure, index) => ({
                    name: `${traderMessage ? traderMessage.value : 'Trader'} ${index + 1}`,
                    index: index,
                    tip: {
                        shipIndex: gameRoundData.cuepos[
                            generalFunctions.alphaToNum(structure)
                            ] - 1,
                        prob: gameRoundData.probcues[index],
                    },
                })),
        };
    },
);

roundProjections.gameRoundTaskLabel = createSelector(
    state => state.round.round.data,
    state => state.round.round.type,
    state => state.application.gameState.gameRoundIndex,
    messageProjections.messageValueFromCode('game-round-trial-run'),
    messageProjections.messageValueFromCode('game-round-main-run'),
    (roundData, roundType, roundIndex, trialMessage, mainRunMessage) => {
        if(!roundType || roundType !== 'game'){
            return null;
        }

        const totalTrialRounds = (
            roundData.gameSumRounds
            && roundData.gameSumRounds[0]
        )
            ? roundData.gameSumRounds[0]
            : '';

        const totalGameRounds = (
            roundData.gameSumRounds
            && roundData.gameSumRounds[1]
        )
            ? roundData.gameSumRounds[1]
            : '';

        if(
            roundData
            && roundData.isTrial === '1'
        ) {
            return `${trialMessage}${roundIndex}/${totalTrialRounds}`;
        }

        if(
            roundData
            && roundData.isTrial !== '1'
        ) {
            return `${mainRunMessage} ${roundIndex}/${totalGameRounds}`;
        }

        return null;
    },
);

roundProjections.cannotChooseAnswerDueToTrialRun = createSelector(
    state => state.round.round.data,
    state => state.application.gameState.tipps,
    (gameRoundData, totalTipsUsed) => {
        return (
            gameRoundData
            && gameRoundData.isTrial === '1'
            && gameRoundData.tippstructures.length !== totalTipsUsed
        );
    },
);

roundProjections.isMainRound = createSelector(
    state => state.round.round.data,
    state => state.round.round.type,
    (roundData, roundType) => {
        return (
            roundType === 'game'
            && roundData.isTrial !== '1'
        );
    },
);

export default roundProjections;