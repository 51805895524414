import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FormikWebform from '../formikWebform/formikWebform';
import * as actions from '../../actions';
import usePrevious from '../../hooks/usePrevious';
import {Spinner} from '@blueprintjs/core';
import Message from '../message/message';
import generalFunctions from '../../utils/functions/general';
import messageProjections from '../../projections/message';
import validations from '../../utils/functions/validations';

const WebformRound = (props) => {
    const {} = props;
    const fields = useSelector(state => state.webform.fields);
    const dispatch = useDispatch();
    const roundWebformId = useSelector(state => state.round.round.data.id);
    const webformTitle = useSelector(state => state.round.round.data.title);
    const webformBody = useSelector(state => state.round.round.data.body);
    const roundType = useSelector(state => state.round.round.type);
    const prevRoundType = usePrevious(roundType);
    const loadingWebformElements = useSelector(state => state.webform.loadingElements);
    const loadingWebformFields = useSelector(state => state.webform.loadingFields);
    const requiredMessage = useSelector(messageProjections.messageValueFromCode('field-is-required-message'));

    const submitWebformAction = useCallback(
        (payload) => dispatch(actions.submitWebform(payload)),
        [dispatch],
    );

    const fetchWebformElementsAction = useCallback(
        (payload) => dispatch(actions.fetchWebformElements(payload)),
        [dispatch],
    );

    const fetchWebformFieldsAction = useCallback(
        (payload) => dispatch(actions.fetchWebformFields(payload)),
        [dispatch],
    );

    useEffect(
        () => {
            if(
                roundType
                && roundType !== prevRoundType
                && roundType === 'webform'
                && roundWebformId
            ) {
                fetchWebformElementsAction({webformId: roundWebformId});
                fetchWebformFieldsAction({webformId: roundWebformId});
            }

        }, [
            roundWebformId,
            roundType,
            prevRoundType,
        ],
    );

    const handleSubmit = (values, formikBag) => {
        // custom validation for required fields
        const requiredErrors = validations.formLevelValidation(fields, requiredMessage, values);
        if(Object.keys(requiredErrors).length > 0){
            return formikBag.setErrors(requiredErrors);
        }

        submitWebformAction({
            sendBody: {
                webform_id: roundWebformId,
                sid: 'dSBasisHS', //todo: handle dynamic scenario when implemented
                ...values,

            },
        });
    };

    if(
        loadingWebformElements
        || loadingWebformFields
    ) {
        return (
            <Spinner/>
        );
    }

    return (
        <div>
            {webformTitle && (
                <Message text={webformTitle}/>
            )}
            {webformBody && (
                <div dangerouslySetInnerHTML={generalFunctions.createMarkup(webformBody)}/>
            )}
            <FormikWebform
                fields={fields}
                handleSubmit={handleSubmit}
            />
        </div>
    );
};

export default WebformRound;