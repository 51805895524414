import * as ActionTypes from '../actions/types';
import xs from 'xstream';
import { BASE_URL } from '../utils/constants/config';
import * as actions from '../actions';

const REQUEST_LOGIN = 'requestLogin';

export function requestLogin(sources) {
    const request$ = sources.ACTION.filter(action => action.type === ActionTypes.LOGIN).map(action => ({
        url: BASE_URL + 'api/login',
        category: REQUEST_LOGIN,
        method: 'POST',
        send: action.payload,
    }));

    let httpResponse$ = sources.HTTP.select(REQUEST_LOGIN)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response => (response.status === 200 ? actions.loginSucceeded(response) : actions.loginFailed(response)));

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}
