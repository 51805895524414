import React, {useCallback} from 'react';
import * as actions from '../../actions';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Intent} from '@blueprintjs/core';
import messageProjections from '../../projections/message';

const NextRoundLink = (props) => {
    const {type} = props;
    const dispatch = useDispatch();
    const previousMessage = useSelector(messageProjections.messageValueFromCode('back-button'));
    const nextMessage = useSelector(messageProjections.messageValueFromCode('next-button'));
    const round = useSelector(state => state.application.gameState.round);
    const hideBackButton = useSelector(state => state.application.hideBackButton);

    const goToNextRoundAction = useCallback(
        (payload) => dispatch(actions.goToNextRound()),
        [dispatch],
    );

    const goToPreviousRoundAction = useCallback(
        (payload) => dispatch(actions.gotToPreviousRound()),
        [dispatch],
    );

    const handleClick = () => {
        goToNextRoundAction();
    };

    const handlePreviousClick = () => {
        goToPreviousRoundAction();
    };


    return (
        <React.Fragment>
            {(
                (round !== '1' && round !== 1)
                && (hideBackButton === false)
            ) && (
                <div style={{marginRight: '8px', display: 'inline-block'}}>
                    <Button
                        outlined
                        text={previousMessage}
                        onClick={handlePreviousClick}
                    />
                </div>
            )}
            <Button
                intent={Intent.PRIMARY}
                type={type ? type : 'button'}
                text={nextMessage}
                onClick={type === 'submit' ? null : handleClick}
            />
        </React.Fragment>
    );

};

export default NextRoundLink;