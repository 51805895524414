const generalFunctions = {};

generalFunctions.postHeader = (csrfToken, sid) => ({
    'X-CSRF-Token': csrfToken,
    sid: sid,
});

generalFunctions.generalHeader = (sid) => ({
    sid: sid,
});

generalFunctions.createMarkup = markupString => ({__html: markupString});

generalFunctions.alphaToNum = (alpha) => {
    let i = 0,
        num = 0,
        len = alpha.length;
    for (; i < len; i++) {
        num = num * 26 + alpha.charCodeAt(i) - 0x40;
    }

    return num - 1;
};

//converts the character num into char starting from zero (eg 0 -> A, 0 -> B 26 -> AA)
generalFunctions.numToAlpha = (num) => {
    let alpha = '';
    for (; num >= 0; num = parseInt(num / 26, 10) - 1) {
        alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
    }

    return alpha;
};

export default generalFunctions;