import * as ActionTypes from '../actions/types';
import xs from 'xstream';
import { BASE_URL } from '../utils/constants/config';
import * as actions from '../actions';
import sampleCombine from 'xstream/extra/sampleCombine';
import generalFunctions from '../utils/functions/general';

const REQUEST_WEBFORM_ELEMENTS = 'requestWebformElements';
const REQUEST_WEBFORM_FIELDS = 'requestWebformFields';
const REQUEST_SUBMIT_WEBFORM = 'requestSubmitWebform';

export function redirectAfterSuccessfulWebformSubmit(sources) {
    const action$ = sources.ACTION
        .filter((action) => action.type === ActionTypes.SUBMIT_WEBFORM_SUCCESS)
        .map((action) => actions.goToNextRound());

    return {
        ACTION: action$,
    };
}

export function requestSubmitWebform(sources) {
    const state$ = sources.STATE;
    const csrfToken$ = state$.map(state => state.applicationUser.csrfToken);
    const sid$ = state$.map(state => state.language.language.byId['sid']);

    const request$ = sources.ACTION
        .filter((action) => action.type === ActionTypes.SUBMIT_WEBFORM)
        .compose(sampleCombine(csrfToken$))
        .compose(sampleCombine(sid$))
        .map(([[action, csrfToken], sid]) => {
            return {
                url: BASE_URL + 'scenarios/form/submit',
                category: REQUEST_SUBMIT_WEBFORM,
                method: 'POST',
                send: action.payload.sendBody,
                headers: generalFunctions.postHeader(csrfToken, sid),
            };
        });

    let httpResponse$ = sources.HTTP.select(REQUEST_SUBMIT_WEBFORM)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response => (
            response.status === 200
                ? actions.submitWebformSuccess(response)
                : actions.submitWebformFailed(response)
        ));

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestWebformElements(sources) {
    const state$ = sources.STATE;
    const csrfToken$ = state$.map(state => state.applicationUser.csrfToken);
    const sid$ = state$.map(state => state.language.language.byId['sid']);
    const language$ = state$.map(state => state.application.language);

    const request$ = sources.ACTION
        .filter((action) => action.type === ActionTypes.FETCH_WEBFORM_ELEMENTS)
        .compose(sampleCombine(csrfToken$))
        .compose(sampleCombine(sid$))
        .compose(sampleCombine(language$))
        .map(([ [[action, csrfToken], sid] , language]) => {
            return {
                url: BASE_URL + `${(language && language !== 'en') ? `${language}/` : ''}webform_rest/${action.payload.webformId}/elements`,
                category: REQUEST_WEBFORM_ELEMENTS,
                method: 'GET',
                headers: generalFunctions.generalHeader(sid),
                withCredentials: true,
            };
        });

    let httpResponse$ = sources.HTTP.select(REQUEST_WEBFORM_ELEMENTS)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response => (
            response.status === 200
                ? actions.fetchWebformElementsSuccess(response)
                : actions.fetchWebformElementsFailed(response)
        ));

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}

export function requestWebformFields(sources) {
    const state$ = sources.STATE;
    const csrfToken$ = state$.map(state => state.applicationUser.csrfToken);
    const sid$ = state$.map(state => state.language.language.byId['sid']);
    const language$ = state$.map(state => state.application.language);

    const request$ = sources.ACTION
        .filter((action) => action.type === ActionTypes.FETCH_WEBFORM_FIELDS)
        .compose(sampleCombine(csrfToken$))
        .compose(sampleCombine(sid$))
        .compose(sampleCombine(language$))
        .map(([ [[action, csrfToken], sid] , language]) => {
            return {
                url: BASE_URL + `${(language && language !== 'en') ? `${language}/` : ''}webform_rest/${action.payload.webformId}/fields`,
                category: REQUEST_WEBFORM_FIELDS,
                method: 'GET',
                send: action.payload.sendBody,
                headers: generalFunctions.generalHeader(sid),
                withCredentials: true,
            };
        });

    let httpResponse$ = sources.HTTP.select(REQUEST_WEBFORM_FIELDS)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response => (
            response.status === 200
                ? actions.fetchWebformFieldsSuccess(response)
                : actions.fetchWebformFieldsFailed(response)
        ));

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}
