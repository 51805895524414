import { createAction } from '@reduxjs/toolkit';
import * as ActionTypes from './types';

export const updateGameState = createAction(ActionTypes.UPDATE_GAME_STATE);

export const goToNextRound = createAction(ActionTypes.GO_TO_NEXT_ROUND);

export const gotToPreviousRound = createAction(ActionTypes.GO_TO_PREVIOUS_ROUND);

export const goToNextRoundAfterGameRoundResultDisplayed = createAction(ActionTypes.GO_TO_NEXT_ROUND_AFTER_GAME_ROUND_RESULT_DISPLAYED);

export const resetGameStateWhenMainGameRoundBegins = createAction(ActionTypes.RESET_GAME_STATE_WHEN_MAIN_GAME_ROUND_BEGINS);

export const selectApplicationLanguage = createAction(ActionTypes.SELECT_APPLICATION_LANGUAGE);

export const changeHideBackButtonState = createAction(ActionTypes.CHANGE_HIDE_BUTTON_STATE);