import React, {useCallback, useEffect} from 'react';
import generalFunctions from '../../utils/functions/general';
import NextRoundLink from '../nextRoundLink/nextRoundLink';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../actions';
import contentProjections from '../../projections/content';

const LINE_HEIGHT = 1.5;

const TextRound = (props) => {
    const {} = props;
    const nid = useSelector(state => state.round.round.data);
    const content = useSelector(contentProjections.textContent(nid));
    const dispatch = useDispatch();

    const fetchContentByIdAction = useCallback(
        (payload) => dispatch(actions.fetchContentById(payload)),
        [dispatch],
    );
    useEffect(() => {
        fetchContentByIdAction({nid: nid});
    }, []);

    if(!content){
        return null;
    }

    return (
        <div>
            <div
                style={{
                    lineHeight: LINE_HEIGHT,
                }}
                dangerouslySetInnerHTML={generalFunctions.createMarkup(content.body)}
            />
            <NextRoundLink/>
        </div>
    )
};

export default TextRound;