import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import roundProjections from '../../projections/round';
import * as actions from '../../actions';
import applicationProjections from '../../projections/application';
import GameRoundResultDialog from '../gameRoundResultDialog/gameRoundResultDialog';
import messageProjections from '../../projections/message';
import generalFunctions from '../../utils/functions/general';
import GameTable from '../gameTable/gameTable';
import Message from '../message/message';
import ExitDialog from '../exitDialog/exitDialog';

const GameRound = (props) => {
    const {} = props;
    const gameState = useSelector(applicationProjections.gameState);
    const gameRound = useSelector(roundProjections.gameRound);
    const gameInstructionsMessage = useSelector(messageProjections.messageValueFromCode('game-round-instructions'));
    const gameRoundDescriptionMessage = useSelector(messageProjections.messageValueFromCode('game-round-description'));
    const invalidTrialSubmit = useSelector(messageProjections.messageValueFromCode('trial-choose-all-traders-message'));
    let currentEarningsMessage = useSelector(messageProjections.messageValueFromCode('current-earnings'));
    let confirmExitMessage = useSelector(messageProjections.messageValueFromCode('close-browser-popup-message'));
    currentEarningsMessage = currentEarningsMessage.replace('[1]', `<br/><span class="guthaben_value" id="money">${gameState.money}</span>`);
    const dispatch = useDispatch();
    const invalidTrialState = useSelector(roundProjections.cannotChooseAnswerDueToTrialRun);
    const [showErrorMessageAboutTrialRound, setShowErrorMessageAboutTrialRound] = useState(false);
    const gameRoundResult = useSelector(state => state.round.gameRoundResult);
    const startTimestamp = useSelector(state => state.round.round.start);
    const hasPlayedAtLeastOneGameRound = useSelector(state => state.application.hasPlayedAtLeastOneGameRound);
    const [exitModalDisplayed, setExitModalDisplayed] = useState(false);

    const updateGameStateAction = useCallback(
        (payload) => dispatch(actions.updateGameState(payload)),
        [dispatch],
    );

    const submitGameRoundAnswer = useCallback(
        (payload) => dispatch(actions.submitGameRoundAnswer(payload)),
        [dispatch],
    );

    const handleOptionSelect = (option) => () => {
        // on trial run user must get all traders tips before submit
        if(invalidTrialState){
            return setShowErrorMessageAboutTrialRound(true);
        }

        // reset show error message state
        if(setShowErrorMessageAboutTrialRound){
            setShowErrorMessageAboutTrialRound(false);
        }

        submitGameRoundAnswer({
            sendBody: {
                ...gameState,
                option: option,
                start: startTimestamp
            },
        });
    };

    if(!gameRound){
        return null;
    }

    return (
        <div id={'turn'}>
            {(gameRoundResult && gameRoundResult.data) && (
                <GameRoundResultDialog/>
            )}
            {showErrorMessageAboutTrialRound && (
                <Message
                    text={invalidTrialSubmit}
                    variant={'error'}
                />
            )}
            <div className={'guthaben'}>
                <div dangerouslySetInnerHTML={generalFunctions.createMarkup(currentEarningsMessage)}/>
            </div>
            <div className="header_text_schiffe">{gameRoundDescriptionMessage}</div>
            <GameTable
                updateGameStateAction={updateGameStateAction}
                gameState={gameState}
                handleOptionSelect={handleOptionSelect}
                gameRound={gameRound}
            />
            <div className="regeln">
                <div dangerouslySetInnerHTML={generalFunctions.createMarkup(gameInstructionsMessage)} />
            </div>
        </div>
    );
};

export default GameRound;