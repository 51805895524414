import * as ActionTypes from '../actions/types';
import xs from 'xstream';
import { BASE_URL } from '../utils/constants/config';
import * as actions from '../actions';
import generalFunctions from '../utils/functions/general';
import sampleCombine from 'xstream/extra/sampleCombine';

const REQUEST_ROUND = 'requestRound';
const REQUEST_SUBMIT_GAME_ANSWER = 'requestSubmitGameRoundAnswer';

export function requestSubmitGameRoundAnswer(sources) {
    const state$ = sources.STATE;
    const csrfToken$ = state$.map(state => state.applicationUser.csrfToken);
    const sid$ = state$.map(state => state.language.language.byId['sid']);

    const request$ = sources.ACTION
        .filter((action) => action.type === ActionTypes.SUBMIT_GAME_ROUND_ANSWER)
        .compose(sampleCombine(csrfToken$))
        .compose(sampleCombine(sid$))
        .map(([combined, sid]) => {
            const [action, csrfToken] = combined;
            return {
                url: BASE_URL + 'scenarios/round/result',
                category: REQUEST_SUBMIT_GAME_ANSWER,
                method: 'POST',
                send: action.payload.sendBody,
                headers: generalFunctions.postHeader(csrfToken, sid),
                withCredentials: false,
            }
        });

    let httpResponse$ = sources.HTTP.select(REQUEST_SUBMIT_GAME_ANSWER)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response => (
            response.status === 200
                ? actions.submitGameRoundAnswerSuccess(response)
                : actions.submitGameRoundAnswerFailed(response)
        ));

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}



export function requestRound(sources) {
    const state$ = sources.STATE;
    const csrfToken$ = state$.map(state => state.applicationUser.csrfToken);
    const sid$ = state$.map(state => state.language.language.byId['sid']);

    const request$ = sources.ACTION
        .filter((action) => action.type === ActionTypes.FETCH_ROUND)
        .compose(sampleCombine(csrfToken$))
        .compose(sampleCombine(sid$))
        .map(([combined, sid]) => {
            const [action, csrfToken] = combined;
            return {
                url: BASE_URL + 'scenarios/resource',
                category: REQUEST_ROUND,
                method: 'POST',
                send: action.payload.sendBody,
                headers: generalFunctions.postHeader(csrfToken, sid),
                withCredentials: false,
            }
        });

    let httpResponse$ = sources.HTTP.select(REQUEST_ROUND)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response => (
            response.status === 200
                ? actions.fetchRoundSuccess(response)
                : actions.fetchRoundFailed(response)
        ));

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}
