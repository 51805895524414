export const BASE_URL = process.env.REACT_APP_BASE_URL;


export const defaultSendBody = {
    sid: 'dSBasisHS',
    round: '1',
    option: 1,
    tipps: 2,
    money: 0,
    count: 0,
};