import React from 'react';
import Ship from '../ship/ship';
import TraderRow from '../traderRow/traderRow';
import {useSelector} from 'react-redux';
import messageProjections from '../../projections/message';

const mapGameStateCountToGuilderImg = {
    300: require('../../assets/images/kisten_300.jpg'),
    270: require('../../assets/images/kisten_270.jpg'),
    240: require('../../assets/images/kisten_240.jpg'),
    210: require('../../assets/images/kisten_210.jpg'),
    180: require('../../assets/images/kisten_180.jpg'),
    150: require('../../assets/images/kisten_150.jpg'),
};

const GameTable = (props) => {
    const {updateGameStateAction, gameState, handleOptionSelect, gameRound} = props;
    const gameRoundCasesMessages = useSelector(messageProjections.messageValueFromCode('game-round-cases'));
    const gameRoundSellLocallyMessage = useSelector(messageProjections.messageValueFromCode('game-round-sell-locally'));
    const gameRoundSellLocallyBtnLabel = useSelector(messageProjections.messageValueFromCode('sell-locally-button'));

    const handleTraderTip = (trader) => {
        updateGameStateAction({
            ...gameState,
            tipps: gameState.tipps + 1,
            count: gameState.count - 30,
        });
    };

    return (
        <table width="100%" className="entscheidungstabelle" id="turnview">
            <tbody>
            <tr>
                <th scope="col" className="credits horizontal">
                    <div className="wrapper_kisten">
                        <div className="kisten_text">{gameRoundCasesMessages}</div>
                        <div className="kisten_bild">
                            <img
                                width="190px"
                                id="counter"
                                src={mapGameStateCountToGuilderImg[gameState.count]}
                            />
                        </div>
                    </div>
                </th>
                {
                    gameRound.ships.map(ship => (
                        <th scope="col" className="schiff horizontal" key={ship.name}>
                            <Ship
                                handleOptionSelect={handleOptionSelect}
                                ship={ship}
                            />
                        </th>
                    ))
                }
                <th scope="col" className="lokal_verkaufen horizontal">
                    <div className="wrapper_lokal_verkaufen">
                        <div className="lokal_verkaufen_text">{gameRoundSellLocallyMessage}</div>
                        <div className="lokal_verkaufen_button">
                            <input
                                type="button"
                                onClick={handleOptionSelect(gameRound.ships.length + 1)}
                                name="dealer"
                                value={gameRoundSellLocallyBtnLabel}
                            />
                        </div>
                    </div>
                </th>
            </tr>
            {
                gameRound.traders.map(trader => (
                    <TraderRow
                        key={trader.name}
                        ships={gameRound.ships}
                        trader={trader}
                        handleTraderTip={handleTraderTip}
                        isBlocked={gameState.tipps < trader.index}
                    />
                ))
            }
            </tbody>
        </table>
    );
};

export default GameTable;