import React from 'react';
import {Radio, RadioGroup} from '@blueprintjs/core';
import {useSelector} from "react-redux";

const TableCheckboxes = (props) => {
    const {formikFieldProps, inputProps, value, meta} = props;
    const specialFormCode = useSelector(state => state.webform.fields.special_form_code);


    return (
        <table border="0" style={{borderSpacing: '10px 1px', tableLayout: 'fixed'}}>
            <tbody>
            {
                (meta && meta.tableHeader) && (
                    <tr>
                        {meta.tableHeader.map(item => (
                            <td key={item.key} align={item.value ? 'center' : ''}>
                                {item.value}
                            </td>
                        ))}
                    </tr>
                )
            }

            {
                (meta && meta.tableRow) && (
                    <tr>
                        {meta.tableRow.map(item => (
                            <td
                                key={item.key}
                                align={item.value ? '' : 'center'}
                                rowSpan={item.value ? '2' : '1'}
                                style={
                                    specialFormCode
                                    && specialFormCode['#default_value'] === 'max_min_range_3' &&
                                    item.value ?
                                        {width: '95px', textAlign: 'center', wordBreak: 'break-all'}
                                        : null
                                }
                            >
                                {
                                    item.value
                                        ? (
                                            item.value
                                        )
                                        : (
                                            <label className=" bp3-radio .modifier">
                                                <input
                                                    {...formikFieldProps}
                                                    type="radio"
                                                    key={item.key}
                                                    value={item.key}

                                                />
                                                <span className="bp3-control-indicator"/>
                                            </label>
                                        )
                                }
                            </td>
                        ))}
                    </tr>
                )
            }
            </tbody>
        </table>
    );
};

export default TableCheckboxes;