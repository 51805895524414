import {createSelector} from '@reduxjs/toolkit';

const contentProjections = {};

contentProjections.textContent = nid => createSelector(
    state => state.content.content.byId[nid],
    content => {
        if(!content){
            return null;
        }

        let body = null;
        try {
            body = content.body[0].value;
        } catch (e) {
            body = null;
        }
        return {
            nid: nid,
            body: body,
        };
    },
);

export default contentProjections;