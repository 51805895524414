import React, {useCallback, useEffect} from 'react';
import * as actions from '../../actions';
import {useDispatch} from 'react-redux';
import LanguageList from '../../components/languageList/languageList';
import useQuery from '../../hooks/useQuery';

const LanguageListContainer = (props) => {
    const {} = props;
    const dispatch = useDispatch();
    const queryParamId = useQuery('id');

    const fetchLanguagesAction = useCallback(
        (payload) => dispatch(actions.fetchLanguages(payload)),
        [dispatch],
    );

    // fetching of languages
    // get the active version of the scenario from the query param called id and send it to the first request made
    // to the API
    useEffect(() => {
        if(queryParamId){
            fetchLanguagesAction({scenarioVersionId: queryParamId});
        } else {
            fetchLanguagesAction();
        }
    }, []);

    return (
        <LanguageList/>
    );
};

export default LanguageListContainer;