import * as ActionTypes from '../actions/types';
import xs from 'xstream';
import {BASE_URL} from '../utils/constants/config';
import * as actions from '../actions';import delay from 'xstream/extra/delay';
import sampleCombine from 'xstream/extra/sampleCombine';
import generalFunctions from '../utils/functions/general';

const REQUEST_CONTENT_BY_ID = 'requestContentById';

export function requestContentById(sources) {
    const state$ = sources.STATE;
    const csrfToken$ = state$.map(state => state.applicationUser.csrfToken);
    const sid$ = state$.map(state => state.language.language.byId['sid']);
    const language$ = state$.map(state => state.application.language);

    const request$ = sources.ACTION
        .filter((action) =>  action.type === ActionTypes.FETCH_CONTENT_BY_ID)
        .compose(delay())
        .compose(sampleCombine(csrfToken$))
        .compose(sampleCombine(sid$))
        .compose(sampleCombine(language$))
        .map(([ [[action, csrfToken], sid] , language]) => {
            return {
                url: BASE_URL + `${(language && language !== 'en') ? `${language}/` : ''}node/${action.payload.nid}?_format=json`,
                category: REQUEST_CONTENT_BY_ID,
                method: 'GET',
                withCredentials: false,
                headers: generalFunctions.generalHeader(sid),
            };
        });

    let httpResponse$ = sources.HTTP.select(REQUEST_CONTENT_BY_ID)
        .map(response => response.replaceError(err => xs.of(err)))
        .flatten()
        .map(response => (
            response.status === 200
                ? actions.fetchContentByIdSuccess(response)
                : actions.fetchContentByIdFailed(response)
        ));

    return {
        ACTION: httpResponse$,
        HTTP: request$,
    };
}
