import { createReducer } from '@reduxjs/toolkit';
import * as ActionTypes from '../actions/types';

const INITIAL_STATE = {
    gameState: {
        sid: 'dSBasisHS',
        round: '1',  // the general round indicator (form, text, game , etc)
        option: 1,
        tipps: 0,
        money: 0,
        count: 300,
        gameRoundIndex: 1, // the game round indicator (eg 1/ 22)
    },
    language: null,
    hideBackButton: false,
    hasPlayedAtLeastOneGameRound: false,
};

const updateGameState = (state, action) => {
    return {
        ...state,
        gameState: {
            ...state.gameState,
            ...action.payload,
        },
    };
};

const goToNextRound = (state, action) => {
    return {
        ...state,
        gameState: {
            ...state.gameState,
            round: (parseInt(state.gameState.round) + 1).toString(),
        },
    };
};

const gotToPreviousRound = (state, action) => {
    if(state.gameState.round && state.gameState.round.toString() === '1' ){
        return state;
    }

    return {
        ...state,
        gameState: {
            ...state.gameState,
            round: (parseInt(state.gameState.round) - 1).toString(),
        },
    };
};

const goToNextRoundAfterGameRoundResultDisplayed = (state, action) => {
    return {
        ...state,
        gameState: {
            ...state.gameState,
            round: (parseInt(state.gameState.round) + 1).toString(),
            count: INITIAL_STATE.gameState.count,
            tipps: INITIAL_STATE.gameState.tipps,
        },
    };
};

const resetGameStateWhenMainGameRoundBegins = (state, action) => {
    return {
        ...state,
        gameState: {
            ...state.gameState,
            gameRoundIndex: 1,
            money: INITIAL_STATE.gameState.money,
        },
    };
};

const selectApplicationLanguage = (state, action) => {
    return {
        ...state,
        language: action.payload.language,
    };
};

const changeHideBackButtonState = (state, action) => {
    return {
        ...state,
        hideBackButton: action.payload,
    };
};

const playedAtLeastOneGameRound = (state, action) => {
    return {
        ...state,
        hasPlayedAtLeastOneGameRound: true,
    };
};

const application = createReducer(INITIAL_STATE, {
    [ActionTypes.UPDATE_GAME_STATE]: updateGameState,
    [ActionTypes.GO_TO_NEXT_ROUND]: goToNextRound,
    [ActionTypes.GO_TO_PREVIOUS_ROUND]: gotToPreviousRound,
    [ActionTypes.GO_TO_NEXT_ROUND_AFTER_GAME_ROUND_RESULT_DISPLAYED]: goToNextRoundAfterGameRoundResultDisplayed,
    [ActionTypes.RESET_GAME_STATE_WHEN_MAIN_GAME_ROUND_BEGINS]: resetGameStateWhenMainGameRoundBegins,
    [ActionTypes.FETCH_ROUND_FAILED]: gotToPreviousRound,
    [ActionTypes.SELECT_APPLICATION_LANGUAGE]: selectApplicationLanguage,
    [ActionTypes.CHANGE_HIDE_BUTTON_STATE]: changeHideBackButtonState,
    [ActionTypes.SUBMIT_GAME_ROUND_ANSWER_SUCCESS]: playedAtLeastOneGameRound,
});

export default application;
