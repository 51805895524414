import React from "react";
import styles from './message.module.css';

const variantMap = {
    'error': styles['message__failure'],
    'success': styles['message__success'],
};

const Message = (props) => {
    const {text, variant} = props;

    return (
        <div>
            <h1 className={variantMap[variant]}>
                {text}
            </h1>
        </div>
    );
};

export default Message;