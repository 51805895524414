import React from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import MainRouter from './routers/mainRouter';
import './assets/styles/main.css';
import './assets/styles/keto.css';
import '@blueprintjs/core/lib/css/blueprint.css';

function App() {
  return (
      <Provider store={store}>
        <MainRouter />
      </Provider>
  );
}

export default App;
