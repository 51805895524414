import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import applicationProjections from '../../projections/application';
import * as actions from '../../actions';
import {useHistory} from  'react-router-dom';
import languageProjections from '../../projections/language';
import {Spinner} from '@blueprintjs/core';

const LanguageList = (props) => {
    const {} = props;
    const history = useHistory();
    const scenario = useSelector(state => state.application.gameState.sid);
    const fetchingLanguages = useSelector(state => state.language.loading);
    const languages = useSelector(languageProjections.languages);
    const gameState = useSelector(applicationProjections.gameState);
    const dispatch = useDispatch();
    // the startTimestamp is required by the API in every fetch next round request.
    const startTimestamp = useSelector(state => state.round.round.start);

    const fetchRoundAction = useCallback(
        (payload) => dispatch(actions.fetchRound(payload)),
        [dispatch],
    );

    const fetchMessagesAction = useCallback(
        (payload) => dispatch(actions.fetchMessages(payload)),
        [dispatch],
    );

    const selectApplicationLanguageAction = useCallback(
        (payload) => dispatch(actions.selectApplicationLanguage(payload)),
        [dispatch],
    );

    const handleClick = (language) => () => {
        history.push(`/${scenario}?lg=${language.code}`);
        const sendBody = {
            sid: gameState.sid,
            round: gameState.round,
            option: gameState.option,
            tipps: gameState.tipps,
            money: gameState.money,
            count: gameState.count,
            start: startTimestamp,
        };
        fetchRoundAction({sendBody});

        selectApplicationLanguageAction({language: language.code});
        fetchMessagesAction({sendBody: {sid: gameState.sid, language: language.code}});
    };

    if(fetchingLanguages){
        return (
            <Spinner/>
        );
    }

    return (
        <ul className="languageSelection">
            {
                languages.map(language => (
                    <li key={language.code}>
                        <a onClick={handleClick(language)}>
                            {language.name}
                        </a>
                    </li>
                ))
            }
        </ul>
    );
};

export default LanguageList;