import { createReducer } from '@reduxjs/toolkit';
import * as ActionTypes from '../actions/types';

const INITIAL_STATE = {
    content:{
        byId: {},
        allIds: [],
    },
    loading: false,
};

const fetchContentById = (state, action) => {
    return state;
};
const fetchContentByIdSuccess = (state, action) => {
    try {
        return {
            ...state,
            content: {
                byId: {
                    ...state.content.byId,
                    [action.payload.body.nid[0].value]: action.payload.body,
                },
                allIds: [
                    ...state.content.allIds,
                    action.payload.body.nid[0].value,
                ],
            },
        };
    } catch (e) {
        return state;
    }
};
const fetchContentByIdFailed = (state, action) => {
    return state;
};

const content = createReducer(INITIAL_STATE, {
    [ActionTypes.FETCH_CONTENT_BY_ID]: fetchContentById,
    [ActionTypes.FETCH_CONTENT_BY_ID_SUCCESS]: fetchContentByIdSuccess,
    [ActionTypes.FETCH_CONTENT_BY_ID_FAILED]: fetchContentByIdFailed,
});

export default content;
