import React from 'react';
import RoundRouter from '../../routers/roundRouter';

const Scenario = (props) => {
    const {} = props;


    return (
        <RoundRouter />
    );
};

export default Scenario;