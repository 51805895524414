import React from 'react';
import TextRound from '../components/textRound/textRound';
import {useSelector} from 'react-redux';
import GameRound from '../components/gameRound/gameRound';
import WebformRound from '../components/webformRound/webformRound';
import {Spinner} from '@blueprintjs/core';
import RedirectRound from "../components/redirectRound/redirectRound";

const RoundRouter = (props)=>{
    const path = useSelector(state => state.round.round.type);
    const loading = useSelector(state => state.round.loading);
    const loadingLanguages = useSelector(state => state.language.loading);
    const loadingMessages = useSelector(state => state.message.loading);

    if(
        loading
        || loadingLanguages
        || loadingMessages
    ){
        return (
            <Spinner/>
        );
    }

    if(path === 'text'){
        return (
            <TextRound/>
        );
    }

    if(path === 'webform'){
        return (
            <WebformRound/>
        );
    }

    if(path === 'game'){
        return (
            <GameRound/>
        );
    }

    if(path === 'redirect'){
        return (
            <RedirectRound/>
        );
    }

    return null;
};

export default RoundRouter;