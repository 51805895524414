import TextField from './textField/textField';
import RadioInput from './RadioInput/RadioInput';
import TextAreaField from './textAreaField/textAreaField';
import TableCheckboxes from './tableCheckboxes/tableCheckboxes';

const widgets = {
    textfield: {
        component: TextField,
    },
    checkboxes: {
        component: RadioInput,
    },
    textarea: {
        component: TextAreaField,
    },
    tableCheckboxes: {
        component: TableCheckboxes,
    },
};

export default widgets;