import React, {useCallback, useEffect, useState} from 'react';
import Scenario from '../../components/scenario/scenario';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../actions';
import applicationProjections from '../../projections/application';
import usePrevious from '../../hooks/usePrevious';
import roundProjections from '../../projections/round';

const ScenarioContainer = (props) => {
    const {} = props;
    const dispatch = useDispatch();
    const gameState = useSelector(applicationProjections.gameState);
    const prevRound = usePrevious(gameState.round);
    const errorLoadingRoundMessage = useSelector(state => state.round.errorLoadingMessage);
    const roundType = useSelector(state => state.round.round.type);
    // the startTimestamp is required by the API in every fetch next round request.
    const startTimestamp = useSelector(state => state.round.round.start);
    const prevRoundType = usePrevious(roundType);
    const isMainRound = useSelector(roundProjections.isMainRound);
    const prevIsMainRound = usePrevious(isMainRound);

    const fetchRoundAction = useCallback(
        (payload) => dispatch(actions.fetchRound(payload)),
        [dispatch],
    );

    const changeHideBackButtonStateAction = useCallback(
        (payload) => dispatch(actions.changeHideBackButtonState(payload)),
        [dispatch],
    );

    useEffect(() => {
        if(
            prevRound
            && gameState
            && gameState.round
            && prevRound.toString() !== gameState.round.toString()
            && !errorLoadingRoundMessage
        ) {
            const sendBody = {
                sid: gameState.sid,
                round: gameState.round,
                option: gameState.option,
                tipps: gameState.tipps,
                money: gameState.money,
                count: gameState.count,
                start: startTimestamp,
            };
            fetchRoundAction({sendBody});
        }

    }, [
        fetchRoundAction,
        gameState.sid,
        gameState.round,
        gameState.option,
        gameState.tipps,
        gameState.money,
        gameState.count,
        prevRound,
        errorLoadingRoundMessage,
        startTimestamp,
    ]);

    // hide back button once enter trial round
    useEffect(
        () => {
            if(
                roundType === 'game'
                && prevRoundType !== 'game'
                && isMainRound === false
            ){
                changeHideBackButtonStateAction(true);
            }

        },
        [roundType, prevRoundType, isMainRound, changeHideBackButtonStateAction],
    );


    return (
        <Scenario />
    );
};

export default ScenarioContainer;