import { createReducer } from '@reduxjs/toolkit';
import * as ActionTypes from '../actions/types';

const INITIAL_STATE = {
    elements: {},
    fields: {},
    loadingElements: false,
    loadingFields: false,
};

const fetchWebformElements = (state, action) => {
    return {
        ...state,
        loadingElements: true,
    };
};

const fetchWebformElementsSuccess = (state, action) => {
    return {
        ...state,
        elements: action.payload.body,
        loadingElements: false,
    };
};

const fetchWebformElementsFailed = (state, action) => {
    return {
        ...state,
        loadingElements: false,
    };
};

const fetchWebformFields = (state, action) => {
    return {
        ...state,
        loadingFields: true,
    };
};
const fetchWebformFieldsSuccess = (state, action) => {
    return {
        ...state,
        fields: action.payload.body,
        loadingFields: false,
    };
};
const fetchWebformFieldsFailed = (state, action) => {
    return {
        ...state,
        loadingFields: false,
    };
};



const webform = createReducer(INITIAL_STATE, {
    [ActionTypes.FETCH_WEBFORM_ELEMENTS]: fetchWebformElements,
    [ActionTypes.FETCH_WEBFORM_ELEMENTS_SUCCESS]: fetchWebformElementsSuccess,
    [ActionTypes.FETCH_WEBFORM_ELEMENTS_FAILED]: fetchWebformElementsFailed,

    [ActionTypes.FETCH_WEBFORM_FIELDS]: fetchWebformFields,
    [ActionTypes.FETCH_WEBFORM_FIELDS_SUCCESS]: fetchWebformFieldsSuccess,
    [ActionTypes.FETCH_WEBFORM_FIELDS_FAILED]: fetchWebformFieldsFailed,
});

export default webform;
