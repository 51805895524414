import applicationUser from './applicationUser';
import application from './application';
import round from './round';
import webform from './webform';
import language from './language';
import content from './content';
import message from './message';

const reducers = {
    message,
    content,
    language,
    webform,
    round,
    application,
    applicationUser,
};

export default reducers;
