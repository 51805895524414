import { createReducer } from '@reduxjs/toolkit';
import * as ActionTypes from '../actions/types';

const INITIAL_STATE = {
    message:{
        byId: {
            'app-title': {
                code: 'app-title',
                value: 'KETO',
            },
            'app-subtitle': {
                code: 'app-subtitle',
                value: 'Kurzer Entscheidungstest Online',
            },
            'app-footer': {
                code: 'app-footer',
                value: 'KETO © Dr. phil. Daniel Hausmann-Thürig, Psychologisches Institut, Universität Zürich',
            },
        },
        allIds: [
            'app-title',
            'app-subtitle',
            'app-footer',
        ],
    },
    loading: false,
};

const fetchMessages = (state, action) => {
    return {
        ...state,
        loading: true,
    };
};

const fetchMessagesSuccess = (state, action) => {
    const allIds = action.payload.body
        .map( (value) => {
            return value.code;
        });
    const byId = action.payload.body.reduce((acc, curr) => {
        return {
            ...acc,
            [curr.code]: curr,
        };
    }, {});
    return {
        ...state,
        message: {
            byId: {
                ...state.message.byId,
                ...byId,
            },
            allIds: [
                ...state.message.allIds,
                ...allIds,
            ],
        },
        loading: false,
    };
};

const fetchMessagesFailed = (state, action) => {
    return {
        ...state,
        loading: false,
    };
};


const message = createReducer(INITIAL_STATE, {
    [ActionTypes.FETCH_MESSAGES]: fetchMessages,
    [ActionTypes.FETCH_MESSAGES_SUCCESS]: fetchMessagesSuccess,
    [ActionTypes.FETCH_MESSAGES_FAILED]: fetchMessagesFailed,
});

export default message;
