import {createSelector} from '@reduxjs/toolkit';

const applicationProjections = {};

applicationProjections.gameState = createSelector(
    state => state.application.gameState,
    (gameState) => ({
            sid: gameState.sid,
            round: gameState.round,
            option: gameState.option,
            tipps: gameState.tipps,
            money: gameState.money,
            count: gameState.count,
            gameRoundIndex: gameState.gameRoundIndex,
            start: gameState.start
    }),
);

export default applicationProjections;
