import React from 'react';
import {InputGroup} from '@blueprintjs/core';

const TextField = (props) => {
    const {formikFieldProps, inputProps, value} = props;

    return (
        <InputGroup
            {...formikFieldProps}
            {...inputProps}
            value={value}
        />
    );
};

export default TextField;
