import { createReducer } from '@reduxjs/toolkit';
import * as ActionTypes from '../actions/types';

const INITIAL_STATE = {
    round:{

    },
    loading: false,
    gameRoundResult: null,
    errorLoadingMessage: null,
};

const fetchRound = (state, action) => {
    return {
        ...state,
        loading: true,
        errorLoadingMessage: INITIAL_STATE.errorLoadingMessage,
    };
};

const fetchRoundSuccess = (state, action) => {
    return {
        ...state,
        round: action.payload.body,
        loading: false,
    };
};

const fetchRoundFailed = (state, action) => {
    return {
        ...state,
        loading: false,
        errorLoadingMessage: 'Failed to fetch round',
    };
};

const submitGameRoundAnswer = (state, action) => {
    return state;
};

const submitGameRoundAnswerSuccess = (state, action) => {
    return {
        ...state,
        gameRoundResult: action.payload.body,
    };
};

const submitGameRoundAnswerFailed = (state, action) => {
    return state;
};

const goToNextRoundAfterGameRoundResultDisplayed = (state, action) => {
    return {
        ...state,
        gameRoundResult: INITIAL_STATE.gameRoundResult,
        errorLoadingMessage: INITIAL_STATE.errorLoadingMessage,
    };
};

const round = createReducer(INITIAL_STATE, {
    [ActionTypes.FETCH_ROUND]: fetchRound,
    [ActionTypes.FETCH_ROUND_SUCCESS]: fetchRoundSuccess,
    [ActionTypes.FETCH_ROUND_FAILED]: fetchRoundFailed,
    [ActionTypes.SUBMIT_GAME_ROUND_ANSWER]: submitGameRoundAnswer,
    [ActionTypes.SUBMIT_GAME_ROUND_ANSWER_SUCCESS]: submitGameRoundAnswerSuccess,
    [ActionTypes.SUBMIT_GAME_ROUND_ANSWER_FAILED]: submitGameRoundAnswerFailed,
    [ActionTypes.GO_TO_NEXT_ROUND_AFTER_GAME_ROUND_RESULT_DISPLAYED]: goToNextRoundAfterGameRoundResultDisplayed,
});

export default round;
