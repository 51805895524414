import {useLocation} from 'react-router-dom';

const splitStingValue = (stringValue) => {
    const splitParam = stringValue
        .split('=');

    if(splitParam.length !== 2){
        return  {splitParamName: null, splitParamValue: null};
    }

    const splitParamName = splitParam[0];
    const splitParamValue = splitParam[1];
    return  {splitParamName, splitParamValue};
};

const useQuery = (desiredParameter) => {
    const locationSearchParams = useLocation().search;

    if(!locationSearchParams){
        return locationSearchParams;
    }

    try {
        const parsedParams = locationSearchParams
            .split('&')
            .reduce((acc, curr, index) => {
                if(index === 0){
                    const {splitParamName, splitParamValue} = splitStingValue(curr);
                    if(!splitParamName && !splitParamValue){
                        return  acc;
                    }

                    return {
                        ...acc,
                        [splitParamName.slice(1)]: splitParamValue,
                    };
                }

                const {splitParamName, splitParamValue} = splitStingValue(curr);
                if(!splitParamName && !splitParamValue){
                    return  acc;
                }

                return {
                    ...acc,
                    [splitParamName]: splitParamValue,
                };

            }, {});

        return desiredParameter && parsedParams[desiredParameter]
            ? parsedParams[desiredParameter]
            : (
                Object.keys(parsedParams).length > 0
                    ? parsedParams
                    : null
            );
    } catch (e){
        return null;
    }

    return null;
};

export default useQuery;