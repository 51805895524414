import React from 'react';
import Content from '../content/content';
import Header from '../header/header';
import Footer from '../footer/footer';

const Layout = props => {
    return (
        <>
            <p>
                <img src={require('../../assets/images/bg_keto.jpg')} className={'bg'} alt={''}/>
            </p>
            <div id={'page-wrap'}>
                <Header/>
                <div className={'main'}>
                    <Content />
                </div>
                <Footer/>
            </div>
        </>
    );
};

export default Layout;
