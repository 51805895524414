import React, {useCallback} from 'react';
import {Dialog, Classes, AnchorButton, Intent} from '@blueprintjs/core';
import {useDispatch, useSelector} from 'react-redux';
import applicationProjections from '../../projections/application';
import * as actions from '../../actions';
import Message from '../message/message';
import messageProjections from '../../projections/message';
import generalFunctions from '../../utils/functions/general';


const RESULT = {
    failure: 0,
    success: 1,
    sold: 2,
};

const GameRoundResultDialog = (props) => {
    const {} = props;
    const gameRoundResult = useSelector(state => state.round.gameRoundResult);
    const gameState = useSelector(applicationProjections.gameState);
    const dispatch = useDispatch();

    const resultSuccessTitle = useSelector(messageProjections.messageValueFromCode('result-popup-success-title'));
    const goOnToTheNextTaskMessage = useSelector(messageProjections.messageValueFromCode('button-go-on-to-the-next-task'));

    let resultSuccessMessage = useSelector(messageProjections.messageValueFromCode('result-popup-success-message'));
    resultSuccessMessage = resultSuccessMessage.replace('[1]', `<span id="result_kisten_1">${gameState.count}</span>`);
    resultSuccessMessage = resultSuccessMessage.replace('[2]', `<span id="result_ertrag_1">${gameRoundResult.data.money - gameState.money}</span>`);

    const resultFailureTitle = useSelector(messageProjections.messageValueFromCode('result-popup-failure-title'));
    const resultFailureMessage = useSelector(messageProjections.messageValueFromCode('result-popup-failure-message'));

    const resultSoldTitle = useSelector(messageProjections.messageValueFromCode('result-popup-sell-locally-title'));
    let resultSoldMessage = useSelector(messageProjections.messageValueFromCode('result-popup-sell-locally-message'));
    let roundResultDialogTitle = useSelector(messageProjections.messageValueFromCode('round-result'));
    resultSoldMessage = resultSoldMessage.replace('[1]', `<span id="result_kisten_1">${gameState.count}</span>`);
    resultSoldMessage = resultSoldMessage.replace('[2]', `<span id="result_ertrag_1">${gameRoundResult.data.money - gameState.money}</span>`);

    const goToNextRoundAction = useCallback(
        () => dispatch(actions.goToNextRoundAfterGameRoundResultDisplayed()),
        [dispatch],
    );

    const updateGameStateAction = useCallback(
        (payload) => dispatch(actions.updateGameState(payload)),
        [dispatch],
    );

    const handleClick = () => {
        updateGameStateAction({
            ...gameState,
            money: gameRoundResult.data.money,
        });
        goToNextRoundAction();
    };


    if(!gameRoundResult){
        return null;
    }

    return (
        <Dialog
            isCloseButtonShown={false}
            autoFocus
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            enforceFocus
            usePortal
            isOpen={true}
            title={''}
        >
            <div className={Classes.DIALOG_BODY}>
                {(
                    gameRoundResult.data.result === RESULT.success
                ) && (
                    <div>
                        <Message
                            text={resultSuccessTitle}
                            variant={'success'}
                        />
                        <div dangerouslySetInnerHTML={generalFunctions.createMarkup(resultSuccessMessage)}/>
                    </div>
                )}

                {(
                    gameRoundResult.data.result === RESULT.failure
                ) && (
                    <div>
                        <Message
                            text={resultFailureTitle}
                            variant={'error'}
                        />
                        <p>{resultFailureMessage}</p>
                    </div>
                )}

                {(
                    gameRoundResult.data.result === RESULT.sold
                ) && (
                    <div>
                        <Message
                            text={resultSoldTitle}
                        />
                        <div dangerouslySetInnerHTML={generalFunctions.createMarkup(resultSoldMessage)}/>
                    </div>
                )}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <AnchorButton
                        onClick={handleClick}
                        intent={Intent.PRIMARY}
                    >
                        {goOnToTheNextTaskMessage}
                    </AnchorButton>
                </div>
            </div>
        </Dialog>
    );
};

export default GameRoundResultDialog;