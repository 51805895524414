import React from 'react';
import { history } from '../store/store';
import { Router } from 'react-router-dom';
import LayoutContainer from '../containers/layout/layoutContainer';

const MainRouter = props => {
    return (
        <Router history={history}>
            <LayoutContainer />
        </Router>
    );
};

export default MainRouter;
