import React, {Fragment} from 'react';
import {Form, Formik} from 'formik';
import NextRoundLink from '../nextRoundLink/nextRoundLink';
import FormikField from '../formikField/formikField';
import {useSelector} from "react-redux";

const FormikWebform = (props) => {
    const {fields, handleSubmit} = props;
    const specialFormCode = useSelector(state => state.webform.fields.special_form_code);
    const rankTitle = useSelector(state => state.webform.fields.rank_title);
    const ranks = rankTitle && rankTitle['#default_value'].split(':');
    const ranksStyle = {
        width: '36%',
        display: 'flex',
        justifyContent: 'space-around',
        fontSize: '12px',
        fontWeight: '500',
        textTransform: 'capitalize',
        marginBottom: '8px',
        marginLeft: '76px',
        fontStyle: 'italic',
    }

    return (
        <Formik
            initialValues={{}}
            onSubmit={handleSubmit}
        >
            {({submitForm}) => (
                <div>
                    <Form>
                        <Fragment>
                            {specialFormCode
                            && specialFormCode['#default_value'] === 'max_min_range_3' ?
                                <div style={ranksStyle}>
                                    <span>{ranks[0]}</span>
                                    <span>{ranks[1]}</span>
                                    <span>{ranks[0]}</span>
                                </div> : null
                            }
                            {
                                Object
                                    .entries(fields)
                                    .map(([key, value]) => (
                                        <FormikField
                                            webformField={value}
                                            key={key}
                                            ranks={ranks}
                                            specialFormCode={specialFormCode}
                                        />
                                    ))
                            }
                        </Fragment>
                        <br/>
                        <NextRoundLink type={'submit'}/>
                    </Form>
                </div>
            )}
        </Formik>
    );
};

export default FormikWebform;