import {createSelector} from '@reduxjs/toolkit';
import _ from 'lodash';

const languageProjections = {};

languageProjections.languages = createSelector(
    state => state.language.language.byId,
    languagesById => {
        // hacky way to sort languages  (API does not provide desired order at all)
        // any other unpredicted language will be put in the last of the queue
        const SORT_ARRAY = [
            'de',
            'fr',
            'it',
            'en',
        ];
        const unsortedList = Object
            .entries(languagesById)
            .filter(([key, value]) => key !== 'sid' )
            .map(([key, value]) => ({
                code: key,
                name: value,
            }));

        return _.sortBy(unsortedList, i => {
            const matchedIndex = SORT_ARRAY.indexOf(i.code);
            return matchedIndex > -1
                ? matchedIndex
                : unsortedList.length;
        });
    },
);

export default languageProjections;