import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import messageProjections from '../../projections/message';

const TraderRow = (props) => {
    const {ships, trader, isBlocked, handleTraderTip} = props;
    const [isAsked, setIsAsked] = useState(false);
    const blockedMessage = useSelector(messageProjections.messageValueFromCode('game-round-question-blocked-label'));
    const alreadyQuestionedMessage = useSelector(messageProjections.messageValueFromCode('game-round-question-answered-label'));
    const questionMessage = useSelector(messageProjections.messageValueFromCode('game-round-question-label'));

    useEffect(() => {
        setIsAsked(false);
    }, [trader]);

    const handleSelect = () => {
        if(!isAsked){
            setIsAsked(true);
            handleTraderTip(trader);
        }
    };


    return (
        <tr>
            <th scope="row" className="haendler vertikal" >
                {trader.name}
                <input
                    type="button"
                    onClick={handleSelect}
                    value={
                        isBlocked
                            ? blockedMessage
                            : (
                                isAsked
                                    ? alreadyQuestionedMessage
                                    : questionMessage
                            )
                    }
                    style={{display: 'inline'}}
                    disabled={isBlocked || isAsked}
                    className={isAsked ? 'dealer_done' : null}
                />
            </th>

            {
                ships.map(ship => (
                    <td key={ship.name}>
                        {(
                            ship.index === trader.tip.shipIndex
                            && isAsked
                            && trader.tip.prob
                        ) && (
                            <>
                                {`${trader.tip.prob}%`}
                            </>
                        )}
                    </td>
                ))
            }
            <td rowSpan="5" className="last">
            </td>
        </tr>
    );
};

export default TraderRow;