import { createReducer } from '@reduxjs/toolkit';
import * as ActionTypes from '../actions/types';

const INITIAL_STATE = {
    currentUser: {
        uid: '1',
        roles: [
            'administrator',
            'administrator',
        ],
        name: 'admin',
    },
    csrfToken: 'UKKGT6sQtSCgVOtu9pRj4cNqDWmnEglVPem0NjwWpqw',
    logoutToken: null,
};

const login = (state, action) => {
    return state;
};

const loginSucceeded = (state, action) => {
    return {
        ...state,
        currentUser: action.payload.body.current_user,
        csrfToken: action.payload.body.csrf_token,
        logoutToken: action.payload.body.logout_token,
    };
};

const loginFailed = (state, action) => {
    return state;
};

const applicationUser = createReducer(INITIAL_STATE, {
    [ActionTypes.LOGIN]: login,
    [ActionTypes.LOGIN_SUCCEEDED]: loginSucceeded,
    [ActionTypes.LOGIN_FAILED]: loginFailed,
});

export default applicationUser;
