import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

const RedirectRound = () => {
    const redirectUrl = useSelector(state => state.round.round.data);

    useEffect(
        () => {
            if(redirectUrl){
                window.location = redirectUrl;
            }
        }
    ,[]);

    return null;
};

export default RedirectRound;