import React, {useCallback, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import messageProjections from '../../projections/message';
import roundProjections from '../../projections/round';
import * as actions from '../../actions';
import usePrevious from '../../hooks/usePrevious';
import applicationProjections from '../../projections/application';
import {Spinner} from '@blueprintjs/core';

const Header = (props) => {
    const {} = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const titleMessage = useSelector(messageProjections.messageValueFromCode('app-title'));
    const subtitleMessage = useSelector(messageProjections.messageValueFromCode('app-subtitle'));
    const gameRoundTaskLabel = useSelector(roundProjections.gameRoundTaskLabel);
    const updateGameStateAction = useCallback(
        (payload) => dispatch(actions.updateGameState(payload)),
        [dispatch],
    );
    const roundType = useSelector(state => state.round.round.type);
    const prevRoundType = usePrevious(roundType);
    const gameState = useSelector(applicationProjections.gameState);
    const fetchingRound = useSelector(state => state.round.loading);
    const prevFetchingRound = usePrevious(fetchingRound);
    const errorLoadingRoundMessage = useSelector(state => state.round.errorLoadingMessage);
    const isMainRound = useSelector(roundProjections.isMainRound);
    const prevIsMainRound = usePrevious(isMainRound);


    const resetGameStateWhenMainGameRoundBeginsAction = useCallback(
        () => dispatch(actions.resetGameStateWhenMainGameRoundBegins()),
        [dispatch],
    );

    // reset game round index when going from trial run to main and vice versa
    useEffect(
        () => {
            if(
                isMainRound
                && prevIsMainRound === false
            ){
                resetGameStateWhenMainGameRoundBeginsAction();
            }
        },
        [isMainRound, prevIsMainRound, resetGameStateWhenMainGameRoundBeginsAction],
    );


    // increment game round counter
    useEffect(
        () => {
            if(
                (roundType === 'game' && prevRoundType === 'game')
                && (!fetchingRound && prevFetchingRound)
                && !errorLoadingRoundMessage
            ) {
                updateGameStateAction({
                    gameRoundIndex: gameState.gameRoundIndex + 1,
                });
            }
        },
        [fetchingRound, prevFetchingRound, errorLoadingRoundMessage, updateGameStateAction, gameState, roundType, prevRoundType],
    );

    const handleClick = () => history.push('/');

    return (
        <div
            className={'header'}
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
            }}
        >
            <div className={'logo'}>
                <img
                    onClick={handleClick}
                    src={require('../../assets/images/uni_zurich_logo_new.jpg')}
                    alt="Logo Uni Zürich"
                    longdesc={'Logo Uni Zürich'}
                    style={{
                        width: '179',
                        height: '62',
                    }}
                />
            </div>
            <div className={'titel'} id={'titelid'}>
                {titleMessage} <br/> {subtitleMessage}
            </div>
            <div id="stepcounter">
                {
                    (fetchingRound && roundType === 'game')
                        ? <Spinner size={Spinner.SIZE_SMALL}/>
                        : <span id="turncount">{gameRoundTaskLabel}</span>
                }
            </div>
        </div>
    );
};

export default Header;