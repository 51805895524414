const validations = {};

validations.createFieldValidator = (field) => answer => {
    if(!field['#pattern']){
        return null;
    }

    if(
        !field['#required']
        && !answer
    ) {
        return null;
    }

    // regex described rule
    if(
        field['#pattern']
        && RegExp(field['#pattern']).test(answer) === false
    ){
        return field['#pattern_error'];
    }

};

validations.formLevelValidation = (fields, requiredMessage = 'Required', values) => {
    const errors = {};
    Object.entries(fields).forEach(([key, field]) => {
        if(
            field['#required']
            && !values[key]
            && !field['#required_error']
        ) {
            errors[key] = requiredMessage;
        } else if (
            field['#required']
            && !values[key]
            && field['#required_error']
        ) {
            return errors[key] = field['#required_error'];
        }
    });

    return errors;
};

export default validations;