import { createReducer } from '@reduxjs/toolkit';
import * as ActionTypes from '../actions/types';

const INITIAL_STATE = {
    language:{
        byId: {},
        allIds: [],
    },
    loading: false,
};


const fetchLanguages = (state, action) => {
    return {
        ...state,
        loading: true,
    };
};

const fetchLanguagesSuccess = (state, action) => {
    const allIds = Object.entries(action.payload.body)
        .map( ([key, value]) => {
            return key;
        });
    return {
        ...state,
        language: {
            byId: {
                ...state.language.byId,
                ...action.payload.body,
            },
            allIds: [
                ...state.language.allIds,
                ...allIds,
            ],
        },
        loading: false,
    };
};

const fetchLanguagesFailed = (state, action) => {
    return {
        ...state,
        loading: false,
    };
};


const language = createReducer(INITIAL_STATE, {
    [ActionTypes.FETCH_LANGUAGES]: fetchLanguages,
    [ActionTypes.FETCH_LANGUAGES_SUCCESS]: fetchLanguagesSuccess,
    [ActionTypes.FETCH_LANGUAGES_FAILED]: fetchLanguagesFailed,
});

export default language;
