import React from 'react';
import widgets from '../widgets/widgets';
import {FormGroup, Intent} from '@blueprintjs/core';
import {Field} from 'formik';
import validations from '../../utils/functions/validations';
import {useSelector} from 'react-redux';
import webformFunctions from '../../utils/functions/webform';

const mapFieldToWidget = (field, webformElement) => {
    if (!field) {
        return null;
    }

    const customWidgetType = webformFunctions.customWidgetType(webformElement);
    const mappedWidget = customWidgetType
        ? widgets[customWidgetType.type]
        : widgets[field['#type']];

    if (!mappedWidget) {
        return null;
    }

    let meta = {};
    let options = field['#options']
        ? Object
            .entries(field['#options'])
            .map(([optionKey, optionValue]) => ({
                value: optionKey,
                label: optionValue,
            }))
        : [];

    if (customWidgetType && customWidgetType.type === 'tableCheckboxes') {
        meta = customWidgetType.meta;
    }

    const inputProps = {
        label: field['#title'],
        required: field['#required'] ? field['#required'] : false,
        name: field['#webform_key'],
        inline: field['#type'] === 'checkboxes',
        options: options,
    };

    const C = mappedWidget.component;
    return {
        widget: C,
        inputProps,
        meta,
    };

};

const FormikField = (props) => {
    const {webformField, specialFormCode, ranks} = props;
    const webformElement = useSelector(state => state.webform.elements[webformField['#webform_key']]);
    const mappedWidget = mapFieldToWidget(webformField, webformElement);
    const hasPlayedAtLeastOneGameRound = useSelector(state => state.application.hasPlayedAtLeastOneGameRound);
    const ranksStyle = {
        width: '43%',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '12px',
        fontWeight: '500',
        textTransform: 'capitalize',
        marginBottom: '2px',
        fontStyle: 'italic',
    };

    if (!mappedWidget) {
        return null;
    }

    return (
        <Field
            validate={validations.createFieldValidator(webformField)}
            name={mappedWidget.inputProps.name}
        >
            {({field, form: {touched, errors, setFieldValue}, meta}) => {
                return (
                    <FormGroup
                        intent={(
                            (
                                (meta.touched && meta.error)
                                || (meta.error && webformField['#type'] === 'checkboxes')
                            )
                                ? Intent.DANGER
                                : Intent.PRIMARY
                        )}
                        helperText={(
                            (meta.touched && meta.error)
                            || (meta.error && webformField['#type'] === 'checkboxes')
                        )
                            ? meta.error
                            : null
                        }
                        labelInfo={mappedWidget.inputProps.required
                            ? <span style={{color: '#C23030'}}>*</span>
                            : null
                        }
                        label={
                            specialFormCode ?
                            specialFormCode['#default_value'] === 'special_checkboxes' ?
                            webformField['#type'] === 'checkboxes' ?
                                <div style={ranksStyle}>
                                    <span>{ranks[0]}</span>
                                    <span>{ranks[1]}</span>
                                </div> :
                                (
                                    hasPlayedAtLeastOneGameRound
                                        ? <strong>{mappedWidget.inputProps.label}</strong>
                                        : mappedWidget.inputProps.label
                                ) :
                                null :
                                (
                                    hasPlayedAtLeastOneGameRound
                                        ? <strong>{mappedWidget.inputProps.label}</strong>
                                        : mappedWidget.inputProps.label
                                )
                        }
                    >
                        <div
                            style={
                                webformField['#type'] === 'textfield'
                                    ? {width: '15%'}
                                    : null
                            }
                        >
                            <mappedWidget.widget
                                formikFieldProps={{
                                    ...field,
                                    intent: (
                                        meta.touched && meta.error
                                            ? Intent.DANGER
                                            : Intent.PRIMARY
                                    ),
                                }}
                                inputProps={mappedWidget.inputProps}
                                value={(field && field.value) ? field.value : ''}
                                meta={mappedWidget.meta}
                            />
                        </div>
                    </FormGroup>
                );
            }}
        </Field>
    );
};

export default FormikField;