const webformFunctions = {};

webformFunctions.customWidgetType = (element) => {
    if(
        !element
        || !element['#options']
        || Object.keys(element['#options']).length < 2
    ){
        return null;
    }

    // tableCheckbox type
    const optionKeys = Object.keys(element['#options']);
    if(
        element['#type'] === 'checkboxes'
        && element[optionKeys[0]]
        && element[optionKeys[0]]['#description']
        && element[optionKeys[optionKeys.length - 1]]
        && element[optionKeys[optionKeys.length - 1]]['#description']
    ){
        return {
            type: 'tableCheckboxes',
            meta: {
                tableHeader: [
                    {
                        key: 'first_description',
                        value: '',
                    },
                    ...optionKeys.map(key => ({
                        key: key,
                        value: element[key]['#title'],
                    })),
                    {
                        key: 'last_description',
                        value: '',
                    },
                ],
                tableRow: [
                    {
                        key: 'first_description',
                        value: element[optionKeys[0]]['#description'],
                    },
                    ...optionKeys.map(key => ({
                        key: key,
                        value: null,
                    })),
                    {
                        key: 'last_description',
                        value: element[optionKeys[optionKeys.length - 1]]['#description'],
                    },
                ],
            },
        };
    }

    return null;
};

export default webformFunctions;