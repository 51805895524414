import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ScenarioContainer from '../containers/scenario/scenarioContainer';
import LanguageListContainer from '../containers/languageListContainer/languageListContainer';

const ContentRouter = (props)=>{

    return(
        <Switch>
            <Route path="/:sid" component={ScenarioContainer} />
            <Route path="/" component={LanguageListContainer} />
        </Switch>
    );
};

export default ContentRouter;