import React from 'react';
import { TextArea} from '@blueprintjs/core';

const TextAreaField = (props) => {
    const {formikFieldProps, inputProps, value} = props;

    return (
        <TextArea
            {...formikFieldProps}
            {...inputProps}
            value={value}
            fill={true}
            growVertically={false}
        />
    );
};

export default TextAreaField;
