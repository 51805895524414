import React from 'react';
import {useSelector} from 'react-redux';
import messageProjections from '../../projections/message';

const Ship = (props) => {
    const {
        handleOptionSelect,
        ship,
    } = props;
    const chooseShipMessage = useSelector(messageProjections.messageValueFromCode('game-round-choose-ship-label'));

    return (
        <div className="wrapper_schiff">
            <div className="schiff">
                <img
                    src={require('../../assets/images/schiff_wasser_kl.jpg')}
                    width="120"
                    _height="137"
                    alt="schiff"
                />
            </div>
            <div className="schiffname">
                <span className="schiffname_value" id="name1">{ship.name}</span>
            </div>
            <div className="schiff_button">
                <input
                    type="button"
                    onClick={handleOptionSelect(ship.index + 1)}
                    name="ship1" value={chooseShipMessage}
                />
            </div>
        </div>
    );
};

export default Ship;